.rowPadding {
    margin-top: 10px;
}

.behaviorPadding {
    float: left;
    margin-bottom: 25px;
    padding-left: 5px;
    border-left-style: solid;
    border-color: #FA6400;
}