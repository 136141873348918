.adnUploadButton {
  padding: 0px !important;
  margin-top: 5px;
  width: auto;
  min-width: 50px !important;
}

.adnFileUpload {
  max-width: 750px !important;
  padding: 10px;
}