.required-field {
  color:red;
}

.error-notification {
  color: red;
  border-color: red;
  border-style: solid;
  border-width: 2px;
  margin-left: 15px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 90%;
  height: fit-content;
}

.documentation {
  color: gray;
  font-size: 80%;
  padding-left: 10px;
  padding-right: 3px;
  height: fit-content;
}

.editButton {
  background-color: white;
  color: green;
}

.deleteButton {
  background-color: white;
  color: red;
}

.form-label {
  margin-right: 10px;
}

.col {
  margin: 5px;
}

h3 {
  padding-top: 10px;
}

.hrSpacing {
  margin-top: 5px;
  margin-bottom: 0px;
}

.accordion-button {
  justify-content: flex-end;
}

.accordion-button::after {
  margin-left: 0px !important;
}

.inline-form-button {
  margin-top: 2em;
}

.refresh-info {
  margin-right: 10px;
  float: inline-end;
}

.modal-backdrop {
  z-index: 0 !important;
  background-color: rgba(0, 0, 0, 0);
}

.accordion-header {
  margin-top: 0px;
}

.adnBreadCrumb {
  font-weight: normal !important;
}

.adnBreadCrumbSelected {
  font-weight: bolder !important;
}

.modal .modal-dialog .modal-content {
  width: fit-content;
}

.buttonRight {
  float: right
}

/* Used for removing select drop down arrows in safari */
.form-select {
  -webkit-appearance: none;
}

.spanBold {
  font-weight: 800;
}

.infoCardSpacing {
  margin-bottom: 25px;
}