.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    min-height: 100vh !important;
    padding: 48px 0 0;
    height: 100%;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
    margin-left: -1rem;
    /* min-height: 100vh !important; */
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    max-width: 200px !important;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper-collapsed {
    margin-left: -1rem;
    /* min-height: 100vh !important; */
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    max-width: 60px !important;
    margin-right: 5px;
}

#sidebar-wrapper-collapsed .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.sidebarCol {
    width: 200px;
    padding-right: 0px !important;
}

.sidebarColCollapsed {
    width: 100px !important;
    max-width: 100px !important;
}

.sidebarCollapsed {
    top: 0;
    bottom: 0;
    left: 0;
    width: 50px !important;
    min-height: 100vh !important;
    padding: 48px 0 0;
    height: 100%;
    margin-right: 5px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
