.noMargin {
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.noPaddingBottom {
    padding-bottom: 0px;
}

.addEditDiv {
    display: inline;
    margin-right: 50px;
    color: #0d6efd;
    cursor: pointer;
}

.addEditIcon {
    margin-right: 5px;
    margin-bottom: 5px;
}

.addEditButton {
    margin-left: -10px;
}

.radioButton {
    margin-right: 5px;
}

.addBehaviorButton {
    margin-top: 2.0em;
}